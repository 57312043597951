<html>
  <head>
    <meta charset="utf-8" />
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
    <title></title>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
    />
  </head>
  <body>
    <br />
    <maintenance-alert-placeholder></maintenance-alert-placeholder>
    <notification-alert-placeholder></notification-alert-placeholder>
    <other-alert-placeholder></other-alert-placeholder>
    <span class="welcomeTitle">{{ "Title" | translate }}</span>
    <br /><br /><br />
    <div>
      <swiper-container
        class="mySwiper"
        space-between="30"
        centered-slides="true"
        autoplay-delay="5000"
        autoplay-disable-on-interaction="false"
      >
        <swiper-slide>
          <div class="image">
            <img src="assets/images/image-1.jpeg" />
          </div>
          <div class="text">
            <b class="title">Delivering proactive, whole-person support that enhances member care while reducing health care costs</b>
            <br /><br /><br />
            We do this by leveraging rare condition expertise  and proprietary tools, as well as the resources of 400+ clinicians and Medical Advisory Board of 30+ physicians.
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="image">
            <img src="../../assets/images/image-2.jpg" />
          </div>
          <div class="text">
            <b class="title">A dedicated Accordant nurse is your member's touchstone to total support</b>
            <br /><br /><br />
             In each contact, every accordant nurse assesses for current knowledge gaps, care motivation and self-management skills.
          </div>
        </swiper-slide>
      </swiper-container>
      <br />
      <div class="button-area">
        <div title="Toggle Carousal Play/Pause" class="carousel-div">
          <div class="circlebutton">
            <mat-icon (click)="processPlayPause('play')" *ngIf="showPlay"
              >play_circle_outline</mat-icon
            >
            <mat-icon (click)="processPlayPause('pause')" *ngIf="showPause"
              >pause_circle_outline</mat-icon
            >
          </div>
          <div class="circlebutton">
            <button
              class="roundBullet"
              title="Go to Slide 1"
              (click)="goToSlide(0)"
            ></button>
          </div>
          <div class="circlebutton">
            <button
              class="roundBullet"
              title="Go to Slide 2"
              (click)="goToSlide(1)"
            ></button>
          </div>
        </div>
      </div>
      <br />
    </div>
    <br />


      <div class="tableuarea">
        <div class="frequentTitle">
          <span class="frequentTitle">My Dashboards</span>
        </div>
        <div style="margin-top: 50px">
          <div *ngIf="showFrequentlyViewed">
            <div id="frequentViews">
              <cvs-loader-overlay-container [isLoading]="showSpinner">
                <ul id="ul_top_hypers">
                  <li *ngFor="let frequent of frequentViews">
                    <div class="imageContainer">
                      <a id="frequentImage" href="{{ frequent.url }}">
                        <img
                          alt="frequent image"
                          class="imageView"
                          [src]="frequent.image"
                        /> </a
                      ><br /><br />
                      <div class="dashboard_name_word_wrap">
                        <b class="frequentName">{{ frequent.name }}</b>
                      </div>
                    </div>
                  </li>
                </ul>
              </cvs-loader-overlay-container>
            </div>
          </div>
        </div>
      </div>

    <br />
    <br />
    <app-pages-layout></app-pages-layout>
  </body>
</html>
