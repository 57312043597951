import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { TableauService } from "../services/tableau.service";
import {
  AuthorizationService,
  CVSAlertService,
  CVSAlertType,
} from "angular-component-library";
import { DomSanitizer } from "@angular/platform-browser";
import { FrequentView } from "../models/frequent-view";
import { MessageService } from "../services/message.service";
import { mergeMap } from "rxjs/operators";
import Utils from "../shared/utility/Utils";
import { TranslateService } from "@ngx-translate/core";
import {EnvConfig} from "../../config/env-config";
import {EnvironmentLoaderService} from "../../config/environment-loader.service";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit, AfterViewInit {
  frequentViews: FrequentView[] = [];
  showSpinner: boolean = false;
  showFrequentlyViewed: boolean = true;
  showUsefulLinks: boolean = false;
  product = "";
  showPause = false;
  showPlay = true;
  protected readonly envConfig: EnvConfig;

  constructor(
    private tableauService: TableauService,
    private authorizationService: AuthorizationService,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private cvsAlertService: CVSAlertService,
    public translateService: TranslateService,
    private readonly envService: EnvironmentLoaderService) {
    this.envConfig = envService.getEnvConfig()
  }

  ngOnInit() {
    this.getImage();
    this.getMaintenanceMessage();
    this.product = this.translateService.currentLang;
    this.showFrequentlyViewed = this.envConfig.viewFrequentlyViewed;
    this.showUsefulLinks = this.envConfig.viewUsefulLinks;
    this.showPlay = false;
    this.showPause = true;
  }

  getImage() {
    this.showSpinner = true;
    this.authorizationService
        .getAuthorizedUser().subscribe(user =>{
          const domain = Utils.findUserDomain(user.email)
      this.tableauService.getFrequentImg(user.email,domain).
      subscribe((dashboards) => {
        this.showSpinner = false;
        this.frequentViews = dashboards.map((dashboard) => {
          const objectURL = "data:image/png;base64," + dashboard.image;
          const img = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          const url =
              "/#/dashboard/" +
              domain+"/"+
              dashboard.id +
              "/" +
              this.encodeRFC3986URIComponent(dashboard.name);
          return { image: img, url, domain:domain, name: dashboard.name, id: dashboard.id };
        });
      })

    })

  }

  encodeRFC3986URIComponent(url: string) {
    return encodeURIComponent(url).replace(
      /[!'()*]/g,
      (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
    );
  }

  ngAfterViewInit() {}

  public config: any = {
    slidesPerView: 1,
    spaceBetween: 25,
    breakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 2.5,
      },
      1280: {
        slidesPerView: 3.5,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      enabled: true,
      draggable: true,
    },
  };

  stopOrStartSlider() {
    let buttons: HTMLElement[] = [];
    const playButton = document.getElementById("play");
    const pauseButton = document.getElementById("pause");
    buttons.push(playButton!);
    buttons.push(pauseButton!);

    const swiper = document.querySelector("swiper-container")!.swiper;

    if (swiper.autoplay.running) {
      swiper.autoplay.stop();
      console.log("swiper stopped!");
    } else {
      swiper.autoplay.start();
      console.log("swiper started!");
    }
    buttons.forEach((button) => button.classList.toggle("hidden"));
  }

  goToSlide(number: number) {
    const swiper = document.querySelector("swiper-container")!.swiper;
    console.log("swiper slides length--: " + swiper.slides.length);
    swiper.slideTo(number);
  }

  getMaintenanceMessage() {
    const date = new Date();
    const [currentDate] = new Date(date).toISOString().split("T");
    this.messageService
      .getAdminTaskMessages(currentDate)
      .subscribe((adminTaskMessages) => {
        if (adminTaskMessages.systemMaintenanceMessage) {
          this.cvsAlertService.sendAlert({
            headline: "Scheduled Maintenance",
            body: adminTaskMessages.systemMaintenanceMessage,
            alertType: CVSAlertType.Warning,
            hideX: true,
            alertDocumentHolderName: "maintenance-alert-placeholder",
          });
        }
        if (adminTaskMessages.notificationMessage) {
          this.cvsAlertService.sendAlert({
            headline: "Notification",
            body: adminTaskMessages.notificationMessage,
            alertType: CVSAlertType.Info,
            alertDocumentHolderName: "notification-alert-placeholder",
          });
        }
        if (adminTaskMessages.otherMessage) {
          this.cvsAlertService.sendAlert({
            headline: "Other",
            body: adminTaskMessages.otherMessage,
            alertType: CVSAlertType.Info,
            alertDocumentHolderName: "other-alert-placeholder",
          });
        }
      });
  }

  processPlayPause(state: string) {
    console.log("the state initiated is " + state);
    const swiper = document.querySelector("swiper-container")!.swiper;
    if (state == "pause") {
      console.log("toggle play on");
      this.showPlay = true;
      this.showPause = false;
      swiper.autoplay.stop();
    } else if (state == "play") {
      console.log("toggle play off");
      this.showPlay = false;
      this.showPause = true;
      swiper.autoplay.start();
    }
  }

}
